.container {
  contain: content;
  box-sizing: content-box;
  gap: rem(15);

  @media bp(md) {
    gap: rem(30);
  }
}

.loading {
  pointer-events: none;
}

.button {
  height: rem(50);
  margin-bottom: rem(15);
}
