.postedBy {
  color: clr(g-20);

  @media bp(md) {
    &:not(.mobileView) {
      display: block;
      padding-top: rem(7);
    }
  }
}

.postedByDate {
  color: clr(g-20);
}
