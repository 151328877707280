.title {
  font-weight: bold;
  line-height: rem(20);

  @mixin transition color;
  @mixin lineClamp 3;

  @media bp(md) {
    font-size: rem(20);
    line-height: rem(26);
  }

  @media bp(xl) {
    font-size: rem(24);
    line-height: rem(30);
  }
}

.container {
  contain: strict;
  overflow: hidden;
  height: rem(90);
  display: grid;
  grid-template-columns: 1fr rem(90);
  gap: rem(15);
  align-items: start;

  &:hover {
    .title {
      color: clr(p-70);
    }
  }

  @media bp(md) {
    height: rem(153);
    gap: rem(30);
    grid-template-columns: 1fr rem(270);
  }

  @media bp(xl) {
    &.large {
      grid-template-columns: 1fr rem(370);
      height: rem(208);

      .title {
        font-size: rem(30);
        line-height: rem(36);

        @mixin lineClamp override-4;
      }

      .image {
        width: rem(370);
        height: rem(208);
      }
    }
  }
}

.right {
  position: relative;
}

.image {
  width: rem(90);
  height: rem(90);

  @media bp(md) {
    width: rem(270);
    height: rem(153);
  }
}

.scoreLine {
  transform: scale(0.65, 0.65);
  transform-origin: 100% 100%;
  color: clr(p-70);
  background-color: clr(w);
  border-radius: rem(2);
  padding: rem(6) rem(10);
  position: absolute;
  right: rem(5);
  bottom: rem(5);

  @media bp(md) {
    transform: scale(1, 1);
    left: rem(10);
    top: rem(10);
    right: auto;
    bottom: auto;
  }
}

.scoreRing {
  position: absolute;
  right: rem(8);
  top: rem(8);
  width: rem(36);
  height: rem(36);
  font-size: rem(13);

  @media bp(md) {
    left: rem(10);
    top: rem(10);
    right: auto;
    width: rem(44);
    height: rem(44);
    font-size: rem(16);
  }
}

.badge {
  width: rem(40);
  height: rem(60);
  position: absolute;
  right: rem(5);
  top: rem(5);

  @media bp(md) {
    width: rem(50);
    height: rem(80);
    top: rem(10);
    right: rem(10);
  }
}

.badgeRing {
  @media bp(md) {
    display: none;
  }
}

.badgeImage {
  background-color: transparent;
  object-fit: contain;
  object-position: right top;
}
