.whiteStyle {
  font-weight: bold;
}

.prefix {
  margin-left: rem(16);
  position: relative;
}

.prefix::before {
  content: '';
  width: rem(3);
  height: rem(3);
  border-radius: 100%;
  background-color: currentcolor;
  position: absolute;
  left: rem(-9);
  top: calc(50% - rem(1));
}

.counter {
  top: rem(2);

  @media bp(md) {
    top: rem(3);
  }
}
