.list {
  display: grid;

  @media bp(lg) {
    grid-template-columns: repeq(2);
  }
}

.loadMore {
  @media bp(lg) {
    grid-column: span 2;
  }
}

.subList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(15);

  @media bp(md) {
    gap: rem(30);
  }
}
