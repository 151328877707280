.hero {
  margin: rem(15);
  min-height: calc(100vh - rem(110));
  contain: content;

  @media bp(lg) {
    margin: rem(40);
    min-height: calc(100vh - rem(160));
  }

  @media bp(xl) {
    min-height: calc(100vh - rem(180));
  }
}

.subtitle {
  margin: rem(25) 0 rem(20) 0;
  color: clr(p-70);
}
