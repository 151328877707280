.container {
  border-style: solid;
  border-width: rem(2);
  border-image-source: linear-gradient(to left, clr(b-20), clr(b-30) 0%);
  border-image-slice: 1;
  padding: rem(20);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media bp(lg) {
    display: grid;
    padding: rem(80);
    grid-template-columns: repeq(2);
    grid-template-rows: auto auto rem(50);
    grid-template-areas: 'title image' 'suggestions image' 'subtitle image' 'button image';
    gap: rem(30) rem(60);
  }

  @media bp(xxl) {
    padding: rem(160);
  }
}

.imageContainer {
  flex: 1;
  margin-bottom: rem(15);
  width: 100%;

  @media bp(lg) {
    align-self: stretch;
    grid-area: image;
    margin: 0;
  }
}

.image {
  object-fit: contain;
  background: transparent;
}

.title {
  background: linear-gradient(to left, clr(b-20), clr(b-30) 0%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: rem(60);
  font-weight: bold;
  line-height: 1;

  @media bp(lg) {
    align-self: start;
    grid-area: title;
    font-size: rem(200);
  }
}

.suggestions {
  display: flex;
  column-gap: rem(12);
  font-size: rem(18);
  font-weight: 600;
  color: clr(p-70);
  margin-top: rem(14);
  flex-wrap: wrap;
  justify-content: center;

  @media bp(lg) {
    row-gap: rem(8);
    line-height: 1.2;
    column-gap: rem(24);
    justify-content: left;
    font-size: rem(20);
    margin: 0;
    grid-area: button;
  }
}

.subtitle {
  font-size: rem(20);
  line-height: rem(28);
  margin-top: rem(10);
  font-weight: bold;

  @media bp(lg) {
    grid-area: subtitle;
    font-size: rem(24);
    line-height: rem(32);
    color: clr(g-40);
    align-self: end;
    margin: 0;
  }
}

.subtitlePlaceholder {
  height: rem(66);

  @media bp(lg) {
    grid-area: subtitle;
    height: rem(64);
  }
}

.buttonPlaceholder {
  height: rem(68);

  @media bp(lg) {
    grid-area: button;
    height: rem(50);
  }
}

.button {
  width: 100%;
  height: rem(50);
  margin-top: rem(18);

  @media bp(lg) {
    grid-area: button;
    width: rem(320);
    margin: 0;
  }
}
