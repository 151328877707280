.scoreLine {
  transform-origin: 100% 100%;
  color: clr(p-70);
  background-color: clr(w);
  border-radius: rem(2);
  padding: rem(6) rem(10);
  position: absolute;
  left: rem(10);
  top: rem(10);

  @media bp(md) {
    &.spaced {
      left: rem(30);
      top: rem(30);
    }
  }
}

.scoreRing {
  position: absolute;
  right: rem(8);
  top: rem(8);
  width: rem(36);
  height: rem(36);
  font-size: rem(13);

  @media bp(md) {
    left: rem(10);
    top: rem(10);
    right: auto;
    width: rem(44);
    height: rem(44);
    font-size: rem(16);
  }
}

.badge {
  width: rem(40);
  height: rem(60);
  position: absolute;
  right: rem(10);
  top: rem(10);

  @media bp(md) {
    width: rem(72);
    height: rem(110);

    &.spaced {
      top: rem(30);
      right: rem(30);
    }
  }
}

.badgeImage {
  background-color: transparent;
  object-fit: contain;
  object-position: right top;
}
