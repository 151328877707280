.container {
  contain: strict;
  overflow: hidden;
  height: rem(90);
  display: grid;
  grid-template-columns: minmax(0, 1fr) rem(90);
  gap: rem(15);
  align-items: start;

  @media (hover: hover) {
    transition: trn(border-color);

    .title {
      transition: trn(color);
    }

    &:hover {
      border-left-color: clr(b);

      .title {
        color: clr(p-70);
      }
    }
  }

  @media bp(lg) {
    &:not(.mobileView) {
      padding-left: rem(20);
      border-left: bdr(2, b/15);
      grid-template-columns: minmax(0, 1fr) rem(143);
      height: rem(80);

      .title {
        font-size: rem(18);
        line-height: 1.44;

        @mixin lineClamp override-2;
      }
    }
  }
}

.title {
  line-height: 1.2;
  font-weight: bold;

  @mixin lineClamp 3;
}

.image {
  width: 100%;
  height: 100%;
}
