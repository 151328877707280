.postedBy {
  color: clr(cw);
}

.postedByDate {
  color: clr(cw);
}

.container {
  contain: strict;
  overflow: hidden;
  position: relative;
  height: rem(170);

  @media bp(md) {
    height: rem(338);
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: rem(5);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  color: clr(cw);
  padding: rem(10);
  justify-content: flex-end;
  background-image: linear-gradient(to bottom, transparent 20%, clr(cb/60) 70%);
  border-radius: rem(2);

  @media bp(md) {
    padding: rem(30);
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
}

.title {
  font-weight: bold;
  line-height: rem(20);

  @mixin lineClamp 4;

  @media bp(md) {
    line-height: rem(40);
    font-size: rem(32);

    @mixin lineClamp override-3;
  }
}
