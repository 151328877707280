@keyframes dots {
  0%,
  20% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  80%,
  100% {
    opacity: 0;
  }
}

.animated {
  overflow: visible;

  &::after {
    content: ' ...';
    animation: dots 1.5s steps(5, end) infinite;
  }
}
